(() => {
  angular.module('app').component('hostPostHospitalizationGuaranteeLettersDetailsOverviewComponent', {
    templateUrl: require('./overview.component.html'),
    controller: LetterOverviewController,
    controllerAs: 'vm',
    bindings: {
      currencyCode: '=',
      letter: '<',
    }
  });

  LetterOverviewController.$inject = [
    '$state',
    '$stateParams',
    'abp.services.app.hostServiceRequest',
    'abp.services.app.masterPolicy',
    'Hms.Employees.EmployeeState',
    'Hms.GuaranteeLetters.GuaranteeLetterState',
    'Hms.GuaranteeLetters.GuaranteeLetterStatus',
    'Hms.GuaranteeLetters.GuaranteeLetterSource',
    'Hms.ServiceRequests.ServiceRequestStatus',
    'Hms.Gender'
  ];

  function LetterOverviewController(
    $state,
    $stateParams,
    hostServiceRequestSvc,
    masterPolicySvc,
    enumEmployeeState,
    enumGuaranteeLetterState,
    enumGuaranteeLetterStatus,
    enumsGuaranteeLetterSource,
    enumsServiceRequestStatus,
    enumsGender
  ) {
    const vm = this;

    vm.loading = 0;
    vm.letterNumber = $stateParams.letterNumber;
    vm.serviceType = $stateParams.serviceType;

    vm.admissionRecord = null;
    vm.postHospitalizationDetails = null;
    vm.masterPolicy = null;

    vm.pickUpRequest = pickUpRequest;

    vm.enums = {
      employeeState: enumEmployeeState,
      guaranteeLetterState: enumGuaranteeLetterState,
      guaranteeLetterStatus: enumGuaranteeLetterStatus,
      guaranteeLetterSource: enumsGuaranteeLetterSource,
      serviceRequestStatus: enumsServiceRequestStatus,
      gender: enumsGender
    };

    init();

    function init() {
      vm.request = vm.letter.request;
      vm.isAssignee = vm.letter.isAssignee;
      vm.canPickUpRequest = vm.letter.canPickUpRequest;
      vm.organization = vm.letter.organization;

      getMasterPolicyDetail();
      setAdmissionRecord();
      setPostHospitalizationDetails();
    }

    function setAdmissionRecord() {
      vm.admissionRecord = {
        guaranteeLetterNumber: vm.letter.admissionGuaranteeLetter.guaranteeLetterNumber,
        claimNumber: vm.letter.admissionGuaranteeLetter.claimNumber,
        ticketNumber: vm.letter.admissionGuaranteeLetter.ticketNumber,
        diagnoses: vm.letter.admissionGuaranteeLetter.diagnoses,
        panelName: vm.letter.admissionGuaranteeLetter.panelName,
        doctors: vm.letter.admissionGuaranteeLetter.doctors,
        admissionDate: vm.letter.admissionGuaranteeLetter.admissionDate,
        dischargeDate: vm.letter.admissionGuaranteeLetter.dischargeDate
      };
    }

    function setPostHospitalizationDetails() {

      const supportingDocuments = [];

      _.forEach(vm.letter.attachments, (attachment) => {
        supportingDocuments.push({
          externalObjectId: attachment.id,
          applied: attachment.isApplied,
          name: attachment.fileName,
          state: attachment.type,
          uploadedTime: attachment.creationTimeOffsetString,
          uploader: attachment.user,
          url: attachment.fullUrl
        });
      })

      vm.postHospitalizationDetails = {
        panelName: vm.letter.panelLocation.name,
        guaranteeLetterNumber: vm.letter.letterNumber,
        guaranteeAmount: vm.letter.guaranteedAmount,
        doctors: vm.letter.doctors.join(',' ),
        appointmentDate: vm.letter.appointmentDate,
        checkedInTime: vm.letter.checkInTimeOffset,
        documents: supportingDocuments,
        letterStatus: vm.letter.status,
        isFinalized: vm.letter.latestRequestType === vm.enums.guaranteeLetterState.Final.id,
        printing: {
          printGlEnabled: vm.letter.printGlEnabled,
          printGlUrl: vm.letter.printUrl
        }
      };
    }

    function getMasterPolicyDetail() {
      vm.loading += 1;
      masterPolicySvc.getMasterPolicyDetailByPatientId({
        id: vm.letter.patient.id
      }).success((data) => {
        vm.isInsuranceBenefitGroup = data != null;
        if (!data) return;  
        vm.masterPolicy = data.masterPolicy;
      }).finally(() => {
        vm.loading -= 1;
      });
    }

    function pickUpRequest(redirectUrl) {
      vm.loading += 1;
      hostServiceRequestSvc.updateServiceRequestProcessorId({ id: vm.request.id })
        .success(() => {
        })
        .finally(() => {
          vm.loading -= 1;
        });

      $state.go(redirectUrl, {
        letterNumber: vm.letterNumber,
        requestId: vm.request.id,
        memberId: vm.letter.patient.id,
        serviceType: vm.serviceType
      });
    }

  }
})();
