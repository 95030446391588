import _ from 'lodash';
import swal from 'sweetalert';

(() => {
  angular
    .module('finance')
    .controller('finance.views.saleTransactions', SaleTransactionsPageController);

  SaleTransactionsPageController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$timeout',
    '$uibModal',
    'abp.services.finance.saleTransactions',
    'moment',
    'financeConstants',
    'abp.services.finance.commonLookup',
    'Hms.Finance.TransactionType',
    'Hms.Finance.PreviousTransactionTenantType',
    'abp.services.app.exportToExcel',
    'Hms.Payments.PaymentMethod',
    'HealthMetrics.Finance.Payments.PaymentState',
    'Hms.Finance.KnownBillCategoryNames',
    'abp.services.app.financeCommon',
    'Hms.SaleTransactions.Status',
    'Hms.Finance.KnownBillingAccountEntityTypes'
  ];

  function SaleTransactionsPageController(
    $scope,
    $state,
    $stateParams,
    $timeout,
    $modal,
    saleTransactionSvc,
    moment,
    financeConsts,
    commonLookupSvc,
    enumTransactionType,
    enumPreviousTransactionTenantType,
    exportToExcelSvc,
    enumPaymentMethod,
    enumPaymentState,
    enumKnownBillCategoryNames,
    portalfinanceCommonSvc,
    enumSaleTransactionStatus,
    enumFinanceKnownBillingAccountEntityTypes,
  ) {
    const vm = this;
    const organization = "Organization";
    const panel = "Panel";
    const member = "Member";
    const subsidiary = "Subsidiary";
    const corporate = "Corporate";

    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.loading = 0;
    vm.exporting = 0;

    vm.transactionCategories = [];
    vm.accountOwners = [];
    vm.countries = [];
    vm.billingAccounts = [];

    vm.categoryOption = [];

    vm.dateRangeModel = new DateRangeModel(
      $stateParams.startDate,
      $stateParams.endDate,
      getSaleTransactions
    );
    vm.requestParams = new RequestParamsModel($stateParams);

    vm.isHost = App.isHost();
    vm.isClinic = App.isClinic();
    vm.isCorporate = App.isCorporate();
    vm.tabIndex = Number(vm.requestParams.isPayments);
    vm.permissions = {
      creditInvoice: abp.auth.isGranted('HealthMetrics.Finance.SaleTransactions.CreditInvoice'),
      payments: abp.auth.isGranted('HealthMetrics.Finance.Payments'),
      createPayment: abp.auth.isGranted('HealthMetrics.Finance.Payments.Create'),
      accountPayables: abp.auth.isGranted('HealthMetrics.Finance.AccountPayables'),
      manualPaymentInstruction: abp.auth.isGranted(
        'HealthMetrics.Finance.AccountPayables.ManualPaymentInstruction'
      ),
    };

    vm.enums = {
      transactionType: enumTransactionType,
      previousTransactionTenantType: enumPreviousTransactionTenantType,
      paymentMethod: enumPaymentMethod,
      paymentState: enumPaymentState,
      knownBillCategoryNames: enumKnownBillCategoryNames,
      transactionStatus: enumSaleTransactionStatus,
      billingAccountEntityType: enumFinanceKnownBillingAccountEntityTypes
    };

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: financeConsts.grid.defaultPageSizes,
      paginationPageSize: financeConsts.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      columnDefs: getGridOptionsColumnDefs(vm.requestParams.isPayments),
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          vm.requestParams.sorting =
            callback.length && callback[0].field
              ? `${callback[0].field} ${callback[0].sort.direction}`
              : null;
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, getSaleTransactions)
        );
      },
      data: [],
    };

    vm.getSaleTransactions = getSaleTransactions;
    vm.lookupBillingAccount = lookupBillingAccount;
    vm.exportToExcel = exportToExcel;
    vm.getGridOptionsColumnDefs = getGridOptionsColumnDefs;
    vm.setActiveView = setActiveView;
    vm.setManualPaymentInstructionNumber = setManualPaymentInstructionNumber;
    vm.generateFinanceReports = generateFinanceReports;
    vm.getCategoryOption = getCategoryOption;
    vm.replaceHtmlTagSymbol = replaceHtmlTagSymbol;
    vm.sendEmail = sendEmail;
    vm.getSettings = getSettings;
    vm.refreshLookupBillingAccount = refreshLookupBillingAccount;

    init();

    // Init function.

    function init() {
      $timeout(() => {
        vm.gridOptions.paginationCurrentPage = Math.floor(
          vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1
        );
      });

      getSettings();
      getCountries();
      getAccountOwners();
      lookupBillingAccount(
        $stateParams.tenancyName,
        () => getSaleTransactions());

      localizeTransactionTypes();
      localizeTransactionTenantType();

      localizePaymentState();
      localizeTransactionStatus();
      localizeTransactionCategory();

      getCategoryOption();
      getTransactionCategories();

      if ($stateParams.isFromPayment) {
        setActiveView(true);
      }
    }

    function localizeTransactionTypes() {
      _.forEach(vm.enums.transactionType, (transactionType) => {
        transactionType.displayName = App.localize(transactionType.name);
      });
    }

    function localizeTransactionTenantType() {
      _.forEach(vm.enums.previousTransactionTenantType, (transactionTenantType) => {
        transactionTenantType.displayName = App.localize(transactionTenantType.name);
      });
    }

    function localizePaymentState() {
      _.forEach(vm.enums.paymentState, (paymentState) => {
        paymentState.displayName = App.localize(paymentState.name);
      });
    }

    function localizeTransactionStatus() {
      _.forEach(vm.enums.transactionStatus, (transactionStatus) => {
        transactionStatus.displayName = App.localize(transactionStatus.name);
      });
    }

    function localizeTransactionCategory() {
      _.forEach(vm.enums.knownBillCategoryNames, (billCategory) => {
        const tenantTypeCategory = _.split(billCategory.name, '.');
        // eslint-disable-next-line prefer-destructuring
        billCategory.parent = tenantTypeCategory[0];
        billCategory.displayName = App.localize(tenantTypeCategory[1]);
      });
    }

    function getCategoryOption() {
      if (vm.isClinic)
        vm.requestParams.transactionTenantType = panel;

      if (vm.isCorporate)
        vm.requestParams.transactionTenantType = organization;

      vm.categoryOption = _.filter(
        vm.enums.knownBillCategoryNames.values,
        (billCategory) => vm.requestParams.transactionTenantType === billCategory.parent
      );
      if (!vm.requestParams.transactionTenantType) {
        vm.requestParams.category = null;
      }
    }

    function getAccountOwners() {
      commonLookupSvc.getAccountOwners().success((data) => {
        if (data) {
          vm.accountOwners = data.items;
        }
      });
    }

    function getCountries() {
      commonLookupSvc.getCountries().success((data) => {
        if (data) {
          vm.countries = data.items;
        }
      });
    }

    function lookupBillingAccount(search, callback = null) {
      const keyword = _.trim(search || '');
      if (keyword.length > 0 && keyword.length < 3) return;

      const defaultMaxCount = 5;
      const enhancedMaxCount = 10;

      commonLookupSvc
        .getBillingAccounts({
          filter: keyword,
          maxResultCount: vm.hasTransactionFilterEnhancement ? enhancedMaxCount : defaultMaxCount,
          id: vm.requestParams.billingAccountId,
        })
        .success((data) => {
          if (data) {
            vm.billingAccounts = data.items;

            if (vm.hasTransactionFilterEnhancement) {
              vm.billingAccounts.forEach((item) => {
                if (item.entityType === corporate.toLowerCase()) {
                  item.entityType = subsidiary.toLowerCase();
                }
              })
            }
          }
        })
        .finally(() => {
          if (callback != null) {
            getSaleTransactions();
          }
        });
    }

    function registerStateParams() {
      if (vm.requestParams.billingAccountId) {
        const billingAccount = _.find(
          vm.billingAccounts,
          (acc) => acc.id === vm.requestParams.billingAccountId
        );

        vm.requestParams.billingAccountName = `(${billingAccount.businessIdentifierCode}) ${billingAccount.name}`;
      }

      if ($stateParams.tenancyName) {
        const hasTenantTypeFilter = $stateParams.transactionTenantType === 'Organization'
          || $stateParams.transactionTenantType === 'Panel'; 
        
          const excludedEntityType = [
            enumFinanceKnownBillingAccountEntityTypes.Insurer.id,
            enumFinanceKnownBillingAccountEntityTypes.Introducer.id,
            enumFinanceKnownBillingAccountEntityTypes.SalesPerson.id,
            enumFinanceKnownBillingAccountEntityTypes.CustomerSuccess.id,
            enumFinanceKnownBillingAccountEntityTypes.Individual.id,
            enumFinanceKnownBillingAccountEntityTypes.ThirdPartyAdministrator.id
          ];

        vm.requestParams.billingAccountId = _.find(
          vm.billingAccounts,
          x => x.businessIdentifierCode === $stateParams.tenancyName
            && (hasTenantTypeFilter && !excludedEntityType.includes(x.entityType))
        ).id;
        
        // Reset tenancy name to prevent overwriting selected billing account

        $stateParams.tenancyName = null;
      }

      $state.transitionTo($state.current, vm.requestParams.toStateParams(), {
        notify: false,
      });
    }

    function getSaleTransactions() {
      registerStateParams();
      const input = vm.requestParams.toInputDto();

      input.transactionTenantType = vm.hasTransactionFilterEnhancement
        && !_.isEmpty(vm.requestParams.employee)
        ? member : input.transactionTenantType;

      vm.loading += 1;
      saleTransactionSvc
        .getSaleTransactions(input)
        .success((data) => {
          localizeSaleTransactionListingItems(data);
          vm.gridOptions.totalItems = data.totalCount;
          vm.gridOptions.data = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getTransactionCategories() {
      commonLookupSvc.getTransactionCategories()
      .success((categories) => {
        vm.transactionCategories = categories;
      });
    }

    function localizeSaleTransactionListingItems(data) {
      _.forEach(data.items, (transaction) => {
        if (transaction.type)
          transaction.typeDisplayName = App.localize(
            _.find(
              enumTransactionType,
              (transactionType) => transactionType.id === transaction.type
            ).name
          );
        if (transaction.category) transaction.category = App.localize(transaction.category);
        if (transaction.tenantType) transaction.tenantType = App.localize(transaction.tenantType);
        if (transaction.paymentMethod != null) {
          const transactionPaymentMethod = _.find(
            enumPaymentMethod,
            (paymentMethod) => paymentMethod.id === transaction.paymentMethod
          );
          transaction.paymentMethod = App.localize(
            `PaymentMethod_${transactionPaymentMethod.name}`
          );
        }

        // Assigns the transaction category name based of the transactionCategoryId
        if (vm.transactionCategories) {
          transaction.transactionCategoryName = vm.transactionCategories
          .find(category =>
            category.id === transaction.transactionCategoryId).name;
        }
      });
    }

    function exportToExcel() {
      const input = vm.requestParams.toInputDto();
      vm.exporting += 1;
      exportToExcelSvc
        .getSaleTransactionsToExcel(input)
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          vm.exporting -= 1;
        });
    }

    function getSettings() {
      vm.loading += 1;
      commonLookupSvc
        .getCommonSettings()
        .success((data) => {
          vm.hasTransactionFilterEnhancement = data.transactionFilterEnhancement;
        })
        .finally();

      vm.loading -= 1;
    }

    /* Models */

    function DateRangeModel(startDate, endDate, eventCallback) {
      this.startDate = startDate ? moment(startDate) : null;
      this.endDate = endDate ? moment(endDate) : null;
      this.options = App.createDateRangePickerOptions();
      this.options.eventHandlers = {
        'apply.daterangepicker': () => {
          eventCallback();
        },
      };
    }

    function RequestParamsModel(params) {
      this.currencyCode = params.currencyCode || null;
      this.countryCode = params.countryCode || null;
      this.accountOwnerId = params.accountOwnerId || null;
      this.billingAccountId = params.billingAccountId || null;
      this.transactionTenantType = params.transactionTenantType || null;
      this.category = params.category || null;
      this.transactionCategoryId = params.transactionCategoryId || null;
      this.transactionType = params.transactionType || null;
      this.transactionNumber = params.transactionNumber || null;
      this.billingAccountName = params.billingAccountName || null;
      this.transactionStatusList = params.transactionStatusList || [];
      this.subsidiary = params.subsidiary || null;
      this.employee = params.employee || null;

      this.isPayments = params.isPayments || false;

      this.skipCount = params.skipCount || 0;
      this.maxResultCount = params.maxResultCount || financeConsts.grid.defaultPageSize;
      this.sorting = params.sorting;

      this.paymentState = params.paymentState;

      this.toInputDto = () => {
        const input = $.extend({}, this);

        if (vm.dateRangeModel.startDate)
          input.startDate = moment(vm.dateRangeModel.startDate).toJSON();
        if (vm.dateRangeModel.endDate) input.endDate = moment(vm.dateRangeModel.endDate).toJSON();
        return input;
      };

      this.toStateParams = () => {
        const startDate = vm.dateRangeModel.startDate
          ? vm.dateRangeModel.startDate.format('YYYY-MM-DD')
          : null;
        const endDate = vm.dateRangeModel.endDate
          ? vm.dateRangeModel.endDate.format('YYYY-MM-DD')
          : null;

        const stateParams = {
          currencyCode: this.currencyCode,
          countryCode: this.countryCode,
          accountOwnerId: this.accountOwnerId,
          billingAccountId: this.billingAccountId,
          transactionTenantType: this.transactionTenantType,
          category: this.category,
          transactionCategoryId: this.transactionCategoryId,
          transactionType: this.transactionType,
          transactionNumber: this.transactionNumber,
          startDate,
          endDate,
          skipCount: this.skipCount,
          maxResultCount: this.maxResultCount,
          sorting: this.sorting,
          billingAccountName: this.billingAccountName,
          isPayments: this.isPayments,
          paymentState: this.paymentState,
          transactionStatusList: this.transactionStatusList,
          subsidiary: this.subsidiary,
          employee: this.employee,
        };
        return stateParams;
      };
    }

    function getGridOptionsColumnDefs(isPayment) {
      const columnDefs = [
        {
          name: App.localize('Actions'),
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
          visible:
            !isPayment || (isPayment && abp.auth.isGranted('HealthMetrics.Finance.Payments')),
        },
        {
          displayName: App.localize('Country'),
          enableSorting: false,
          field: 'countryCode',
          width: 30,
          visible: vm.isHost,
          cellTemplate: 'countryTemplate',
        },
        {
          displayName: App.localize('Date'),
          enableSorting: false,
          field: 'date',
          cellFilter: "momentFormat: 'L'",
          width: 80,
        },
        {
          displayName: App.localize('TenantType'),
          enableSorting: false,
          field: 'tenantType',
          minWidth: 100,
          visible: vm.isHost && !isPayment,
        },
        {
          displayName: App.localize('Category'),
          enableSorting: false,
          field: 'transactionCategoryName',
          minWidth: 100,
          visible: !isPayment,
        },
        {
          displayName: App.localize('TransactionType'),
          enableSorting: false,
          field: 'typeDisplayName',
          minWidth: 100,
          visible: !isPayment,
        },
        {
          displayName: App.localize('TransactionNumber'),
          enableSorting: false,
          field: 'transactionNumber',
          cellTemplate: 'transactionNumberTemplate',
          minWidth: 100,
          visible: !isPayment,
        },
        {
          displayName: App.localize('PaymentInstruction'),
          enableSorting: false,
          field: 'paymentInstruction',
          minWidth: 100,
          visible: !isPayment && vm.permissions.accountPayables,
          cellTemplate: 'paymentInstructionTemplate',
        },
        {
          displayName: App.localize('BillingAccountName'),
          enableSorting: false,
          field: 'billingAccountName',
          minWidth: 100,
          cellTemplate: 'billingAccountTemplate',
        },
        {
          displayName: App.localize('BillingAddressName'),
          enableSorting: false,
          field: 'billingAddressName',
          visible: !isPayment,
          minWidth: 100,
        },
        {
          displayName: App.localize('Balance'),
          field: 'outstandingAmount',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          cellClass: 'font-bold',
          enableSorting: false,
          minWidth: 100,
          visible: !isPayment,
        },
        {
          displayName: App.localize('PreTaxAmount'),
          enableSorting: false,
          field: 'preTaxAmount',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          minWidth: 100,
          visible: !isPayment,
        },
        {
          displayName: App.localize('TaxAmount'),
          enableSorting: false,
          field: 'taxAmount',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          minWidth: 100,
          visible: !isPayment,
        },
        {
          displayName: App.localize('Total'),
          enableSorting: false,
          field: 'totalAmount',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          minWidth: 100,
          visible: !isPayment,
        },
        {
          displayName: App.localize('Status'),
          enableSorting: false,
          field: 'transactionStatus',
          minWidth: 100,
          cellTemplate: 'statusTemplate',
          visible: !isPayment,
        },
        {
          displayName: App.localize('Amount'),
          enableSorting: false,
          field: 'totalAmount',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          minWidth: 100,
          visible: isPayment,
        },
        {
          displayName: App.localize('PaymentMethod'),
          enableSorting: false,
          field: 'paymentMethod',
          minWidth: 100,
          visible: vm.isHost && isPayment,
        },
        {
          displayName: App.localize('ExcessAmount'),
          enableSorting: false,
          field: 'outstandingAmount',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          minWidth: 100,
          cellTemplate: 'excessAmountTemplate',
          visible: isPayment,
        },
        {
          displayName: App.localize('Status'),
          enableSorting: false,
          field: 'paymentState',
          minWidth: 100,
          cellTemplate: 'paymentStatusTemplate',
          visible: vm.isHost && isPayment,
        },
        {
          displayName: App.localize('PaymentNumber'),
          enableSorting: false,
          field: 'transactionNumber',
          minWidth: 100,
          visible: isPayment,
        },
        {
          displayName: App.localize('Reference'),
          enableSorting: false,
          field: 'remarks',
          minWidth: 100,
          visible: vm.isHost && isPayment,
        },
        {
          displayName: App.localize('CreationTime'),
          enableSorting: false,
          field: 'creationTime',
          minWidth: 100,
          cellFilter: "momentFormat: 'L LT'",
        },
      ];

      return columnDefs;
    }

    function setActiveView(viewId) {
      if (vm.requestParams.isPayments !== viewId) {
        vm.requestParams.isPayments = viewId;
        vm.tabIndex = Number(vm.requestParams.isPayments);
        vm.gridOptions.paginationPageSize = financeConsts.grid.defaultPageSize;
        vm.gridOptions.paginationCurrentPage = 1;
        vm.gridOptions.columnDefs = getGridOptionsColumnDefs(viewId);

        getSaleTransactions();
      }
    }

    function setManualPaymentInstructionNumber(saleTransaction) {
      if (!vm.permissions.manualPaymentInstruction) return;
      swal(
        {
          title: App.localize('ManualPaymentInstruction'),
          text: App.localize(
            'SetManualPaymentInstructionNumberForX',
            saleTransaction.transactionNumber
          ),
          type: 'input',
          showCancelButton: true,
          closeOnConfirm: false,
          confirmButtonColor: '#1ab394',
          inputPlaceholder: App.localize('PaymentInstructionNumber'),
          inputValue: saleTransaction.manualPaymentInstructionNumber,
          showLoaderOnConfirm: true,
        },
        (inputValue) => {
          if (inputValue === false) return false;
          saleTransactionSvc
            .setManualPaymentInstructionNumber({
              transactionNumber: saleTransaction.transactionNumber,
              manualPaymentInstructionNumber: inputValue,
            })
            .success(() => {
              abp.notify.info(App.localize('SuccessfullySaved'));
              swal.close();
              getSaleTransactions();
            });
          return false;
        }
      );
    }

    function generateFinanceReports() {
      $modal.open({
        templateUrl: require('./generateFinanceReportsModal.html'),
        controller: 'finance.views.saleTransactions.generateFinanceReportsModal as vm',
        backdrop: 'static',
      });
    }

    /* To handle edge case for billing account dropdown where a billing account uses '<'/'>'
    in the name. */
    function replaceHtmlTagSymbol(string) {
      return string.replace('<', '&#10092;').replace('>', '&#10093;');
    }

    function sendEmail(saleTransaction) {
      let params = {};

      portalfinanceCommonSvc
      .getEmailAddresses({
        billingAccountKey: saleTransaction.billingAccountKey
      })
      .success((data) => {
        swal(
          {
            title: App.localize('SendEmailForX', saleTransaction.transactionNumber),
            text: App.localize('EmailAddresses'),
            type: 'input',
            showCancelButton: true,
            closeOnConfirm: false,
            confirmButtonColor: '#1ab394',
            inputPlaceholder: App.localize('EmailAddressesNarrative'),
            inputValue: data.emailAddresses,
            showLoaderOnConfirm: true,
          },
          (inputValue) => {
            if (inputValue === false) return false;
            if (inputValue === '') {
              swal.showInputError(App.localize('InvalidEmailAddress'));
              return false;
            }

            // Assign parameters and API call based on the sale transaction type.

            if (saleTransaction.type === 1) { // Invoice.
              params = {
                emailAddresses: inputValue,
                billingAccountName: saleTransaction.billingAccountName,
                transactionNumber: saleTransaction.transactionNumber,
                date: saleTransaction.date,
                currencyCode: saleTransaction.currencyCode,
                amount: saleTransaction.outstandingAmount,
                accountOwnerId: saleTransaction.accountOwnerId
              }

              portalfinanceCommonSvc
              .sendInvoiceEmail(params)
              .success(() => {
                abp.notify.info(App.localize('EmailSent'));
                swal.close();
                getSaleTransactions();
              });
            }
            else if (saleTransaction.type === 2) { // Credit memo.
              params = {
                emailAddresses: inputValue,
                billingAccountName: saleTransaction.billingAccountName,
                transactionNumber: saleTransaction.transactionNumber,
                date: saleTransaction.date,
                currencyCode: saleTransaction.currencyCode,
                amount: saleTransaction.outstandingAmount,
                accountOwnerId: saleTransaction.accountOwnerId
              }

              portalfinanceCommonSvc
              .sendCreditMemoEmail(params)
              .success(() => {
                abp.notify.info(App.localize('EmailSent'));
                swal.close();
                getSaleTransactions();
              });
            }
            else if (!saleTransaction.type) { // Payment.
              params = {
                emailAddresses: inputValue,
                billingAccountName: saleTransaction.billingAccountName,
                paymentNumber: saleTransaction.transactionNumber,
                paymentDate: saleTransaction.date,
                currencyCode: saleTransaction.currencyCode,
                amount: saleTransaction.totalAmount,
                accountOwnerId: saleTransaction.accountOwnerId
              }

              portalfinanceCommonSvc
              .sendPaymentEmail(params)
              .success(() => {
                abp.notify.info(App.localize('EmailSent'));
                swal.close();
                getSaleTransactions();
              });
            }
            return false;
          }
        );
      })
    }

    function refreshLookupBillingAccount(search) {
      if (search) {
        lookupBillingAccount(search);
      }
    }
  }
})();
